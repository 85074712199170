@font-face {
  font-family: 'sparkd';
  src:  url('fonts/sparkd.eot?f2dj20');
  src:  url('fonts/sparkd.eot?f2dj20#iefix') format('embedded-opentype'),
    url('fonts/sparkd.ttf?f2dj20') format('truetype'),
    url('fonts/sparkd.woff?f2dj20') format('woff'),
    url('fonts/sparkd.svg?f2dj20#sparkd') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'sparkd' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right:before {
  content: "\e900";
  color: #56595a;
}
.icon-arrow-left:before {
  content: "\e901";
  color: #56595a;
}
.icon-search:before {
  content: "\e902";
  color: #56595a;
}
.icon-close:before {
  content: "\e903";
  color: #56595a;
}
.icon-edit:before {
  content: "\e904";
  color: #56595a;
}
.icon-done:before {
  content: "\e905";
  color: #56595a;
}
.icon-favorites-outlined:before {
  content: "\e906";
  color: #56595a;
}
.icon-favorites:before {
  content: "\e907";
  color: #56595a;
}
.icon-share:before {
  content: "\e908";
  color: #56595a;
}
.icon-send:before {
  content: "\e909";
  color: #56595a;
}
.icon-email:before {
  content: "\e90a";
  color: #56595a;
}
.icon-flashlight-on:before {
  content: "\e90b";
  color: #56595a;
}
.icon-flashlight-off:before {
  content: "\e90c";
  color: #56595a;
}
.icon-qr-scanner:before {
  content: "\e90d";
  color: #56595a;
}
.icon-image-recognition:before {
  content: "\e90e";
  color: #56595a;
}
.icon-cards:before {
  content: "\e90f";
  color: #56595a;
}
.icon-profile:before {
  content: "\e910";
  color: #56595a;
}
.icon-tickets:before {
  content: "\e911";
  color: #56595a;
}
.icon-docs:before {
  content: "\e912";
  color: #56595a;
}
.icon-lock:before {
  content: "\e913";
  color: #56595a;
}
.icon-about:before {
  content: "\e914";
  color: #56595a;
}
