.route-wrapper {
    position: relative;
    width: 100% !important;
}

.route-wrapper > div {
    position: absolute;
    width: 100% !important;
}


.list-unstyled {
    list-style: none;
    padding-left:0;
}

.list-unstyled > li {
    padding: 5px 0;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.navigation-wrapper.active {
     top: 0;
     transition: all 400ms ease-in;
 }
.navigation-wrapper.hidden {
transition: all 400ms ease-out;
transform: translate(0, -100%);
}

.example-enter {
    opacity: 0.01;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.example-leave {
    opacity: 1;
}

.example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}